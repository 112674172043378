<template>
  <div class="create-policy-modal">
    <div>
      <b-form-group>
        <b-input-group class="mb-2 d-flex flex-column">
          <div>
            <label for="name-in">Name</label>
            <b-form-input
              id="name-in"
              v-model="policy.name"
              placeholder="Enter a name..."
            />
          </div>
        </b-input-group>

        <b-input-group class="mb-2 d-flex flex-column">
          <div>
            <label for="description-in">Description</label>
            <b-form-textarea
              id="description-in"
              v-model="policy.description"
              placeholder="And a description..."
              rows="5"
            />
          </div>
        </b-input-group>

        <b-input-group class="mb-2 d-flex flex-column">
          <label>Enterprise</label>
          <enterprise-search-select
            :selected="selectedEnterprise"
            @change="updateSelectedEnterprise"
          />
        </b-input-group>

        <b-input-group class="mb-2 d-flex flex-column">
          <div>
            <label for="filename-in">MPOS Filename</label>
            <b-form-input
              id="filename-in"
              v-model="policy.policy.filename"
              class="d-flex flex-column"
              placeholder="Filename..."
            />
          </div>
        </b-input-group>

        <b-input-group class="mb-2 d-flex flex-column">
          <div>
            <label for="filename-in">Onboard Keyboard Enabled?</label>
            <b-select
              v-model="policy.policy.onboard"
              :options="onboardOptions"
            />
          </div>
        </b-input-group>

        <b-input-group class="d-flex flex-row-reverse">
          <b-btn
            :class="{ disabled: !checkFormValid() }"
            variant="primary"
            @click="createPolicy"
          >Create Policy
          </b-btn>
          <b-btn class="d-inline mr-2" @click="$emit('close')">Cancel</b-btn>
        </b-input-group>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import EapiPolicyService from "@/services/EapiPolicyService";
import helperService from "@/services/HelperService";
import EnterpriseSearchSelect from "@/components/input/PaginatedSearchSelect/EnterpriseSearchSelect.vue";

export default {
  name: "CreateEapiPolicy",
  components: {
    EnterpriseSearchSelect,
  },
  data() {
    return {
      onboardOptions: [
        { value: false, text: "No" },
        { value: true, text: "Yes" },
      ],
      policy: {
        name: "",
        description: "",
        enterprise_uid: "",
        policy: {
          s3_link: "",
          filename: "",
          onboard: false,
        },
      },
      selectedEnterprise: null,
      loading: true,
    };
  },
  methods: {
    async createPolicy() {
      try {
        await EapiPolicyService.createPolicy(this.policy);
        helperService.showNotfySuccess(
          this.$toast,
          "Added policy successfully"
        );
      } catch (err) {
        helperService.showNotfyErr(
          this.$toast,
          err,
          "Could not get policies, please refresh and try again"
        );
      } finally {
        this.$emit("refresh");
      }
    },
    checkFormValid() {
      const name = this.policy.name.length > 0;
      const filename = this.policy.policy.filename.length > 0;
      const enterpriseUid = this.policy.enterprise_uid.length > 0;

      return name && filename && enterpriseUid;
    },
    updateSelectedEnterprise(enterprise) {
      this.selectedEnterprise = enterprise;
      if (!this.selectedEnterprise) {
        this.policy.enterprise_uid = "";
      } else {
        this.policy.enterprise_uid = this.selectedEnterprise.uid;
      }
    }
  },
};
</script>

<style scoped></style>
