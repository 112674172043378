<style lang="scss"></style>
<!--eslint-disable-->
<template>
  <div>
    <b-card title="EAPI Policies">
      <p class="d-inline">
        This page shows all EAPI policies. You can manage policies from this
        page.
      </p>
      <b-btn
        class="float-right"
        variant="success"
        @click="$bvModal.show('modal-create-eapi-policy')"
        >Create new policy
      </b-btn>
    </b-card>
    <b-card no-body>
      <div class="p-2">
        <div class="col-5">
          <label>Enterprise</label>
          <enterprise-search-select
            :selected="selectedEnterprise"
            @change="updateSelectedEnterprise"
          />
        </div>
        <b-btn
          class="float-right"
          variant="primary"
          @click="$bvModal.show('modal-bulk-update-eapi-policy')"
        >
          Bulk update
        </b-btn>
      </div>

      <b-table
        :current-page="currentPage"
        :fields="headers"
        :items="policies"
        :per-page="0"
        class="data-table pb-0"
        hover
      >
        <template #cell(description)="data">
          <div
            @mouseleave="shrinkDescription"
            @mouseover="expandDescription(data.item.id)"
          >
            {{ showDescription(data.item.id, data.item.description) }}
          </div>
        </template>

        <template #cell(enterprise_uid)="data">
          {{ data.item.enterprise_uid }}
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            class="m-0"
            no-caret
            right
            toggle-class="text-decoration-none"
            variant="link"
          >
            <template #button-content>
              <i class="fas fa-ellipsis-v"></i>
            </template>
            <b-dropdown-item-button
              v-if="$can('Update', 'Policy')"
              class="full-nested-btn"
              @click="showPolicyModal(data.item, 'modal-update-eapi-policy')"
            >
              Update
            </b-dropdown-item-button>
            <b-dropdown-item-button
              v-if="$can('Delete', 'Policy')"
              class="full-nested-btn"
              @click="showPolicyModal(data.item, 'modal-delete-eapi-policy')"
            >
              Delete
            </b-dropdown-item-button>
          </b-dropdown>
        </template>
      </b-table>
      <b-row>
        <b-col v-if="total_count > filters.page_size" class="my-1" md="6">
          <b-pagination
            v-model="currentPage"
            :per-page="filters.page_size"
            :total-rows="total_count"
            class="my-0"
            @change="getMorePolicies"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal id="modal-create-eapi-policy" hide-footer title="Create policy">
      <create-eapi-policy
        @close="closeModals()"
        @refresh="refreshPolicies"
      ></create-eapi-policy>
    </b-modal>

    <b-modal id="modal-update-eapi-policy" hide-footer title="Update policy">
      <update-eapi-policy
        :existing-policy="selectedPolicy"
        @close="closeModals()"
        @refresh="refreshPolicies"
      ></update-eapi-policy>
    </b-modal>

    <b-modal id="modal-delete-eapi-policy" hide-footer title="Delete policy">
      <delete-modal
        v-if="selectedPolicy"
        :subtitle="selectedPolicy.name"
        title="Are you sure you wish to delete this EAPI policy"
        @close="closeModals()"
        @delete="deletePolicy(selectedPolicy.id)"
      ></delete-modal>
    </b-modal>

    <b-modal
      id="modal-bulk-update-eapi-policy"
      hide-footer
      title="Bulk update policies"
    >
      <bulk-update-eapi-policy
        @close="closeModals()"
        @refresh="refreshPolicies"
      ></bulk-update-eapi-policy>
    </b-modal>
  </div>
</template>
<!--eslint-enable-->

<script>
import EapiPolicyService from "@/services/EapiPolicyService";
import CreateEapiPolicy from "@/views/policies/sections/CreateEapiPolicy.vue";
import UpdateEapiPolicy from "@/views/policies/sections/UpdateEapiPolicy.vue";
import BulkUpdateEapiPolicy from "@/views/policies/sections/BulkUpdateEapiPolicy.vue";
import helperService from "@/services/HelperService";
import DeleteModal from "../../components/modals/DeleteModal.vue";
import EnterpriseSearchSelect from "@/components/input/PaginatedSearchSelect/EnterpriseSearchSelect.vue";

export default {
  name: "EapiPolicies",
  components: {
    EnterpriseSearchSelect,
    CreateEapiPolicy,
    UpdateEapiPolicy,
    BulkUpdateEapiPolicy,
    DeleteModal,
  },
  data() {
    return {
      selectedPolicy: null,
      policies: [],
      headers: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "description",
          label: "Description",
        },
        {
          key: "policy.filename",
          label: "Application",
        },
        {
          key: "enterprise_uid",
          label: "Enterprise UID",
        },
        {
          key: "actions",
          label: "",
          class: "text-right",
        },
      ],
      showingDescription: 0,
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 25,
        enterprise_uid: "",
      },
      total_count: 0,
      selectedEnterprise: null,
    };
  },
  mounted() {
    this.getPolicies();
  },
  methods: {
    async getPolicies() {
      try {
        const res = await EapiPolicyService.getPolicies(this.filters);
        this.policies = res.data.data;
        this.filters.page = res.data.meta.page;
        this.filters.page_size = res.data.meta.page_size;
        this.total_count = res.data.meta.total_count;
      } catch (err) {
        helperService.showNotfyErr(
          this.$toast,
          err,
          "Could not get policies, please refresh and try again"
        );
      }
    },
    getMorePolicies(val) {
      this.filters.page = val - 1;
      this.getPolicies();
    },
    async deletePolicy(id) {
      try {
        await EapiPolicyService.deletePolicy(id);
        helperService.showNotfySuccess(
          this.$toast,
          `Deleted policy ${this.selectedPolicy.name} Successfully`
        );
        this.refreshPolicies();
      } catch (err) {
        helperService.showNotfyErr(
          this.$toast,
          err,
          "Could not delete policy, please refresh and try again"
        );
      }
    },
    refreshPolicies() {
      this.closeModals();
      this.getPolicies();
    },
    closeModals() {
      this.$bvModal.hide("modal-create-eapi-policy");
      this.$bvModal.hide("modal-update-eapi-policy");
      this.$bvModal.hide("modal-delete-eapi-policy");
      this.$bvModal.hide("modal-bulk-update-eapi-policy");
    },
    setSelectedPolicy(policy) {
      this.selectedPolicy = policy;
    },
    showPolicyModal(policy, modalName) {
      this.setSelectedPolicy(policy);
      this.$bvModal.show(modalName);
    },
    showDescription(id, description) {
      if (description.length > 50 && this.showingDescription !== id) {
        return `${description.slice(0, 47)}...`;
      }
      return description;
    },
    expandDescription(id) {
      this.showingDescription = id;
    },
    shrinkDescription() {
      this.showingDescription = 0;
    },
    updateSelectedEnterprise(enterprise) {
      this.selectedEnterprise = enterprise;
      if (!this.selectedEnterprise) {
        this.filters.enterprise_uid = "";
      } else {
        this.filters.enterprise_uid = this.selectedEnterprise.uid;
      }
      this.getMorePolicies(1);
    }
  },
};
</script>

<style scoped></style>
