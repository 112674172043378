<template>
  <div>
    <div v-if="!loading" class="create-policy-modal">
      <div>
        <b-form-group>
          <b-input-group class="mb-2 d-flex flex-column">
            <div>
              <label for="name-in">Name</label>
              <b-form-input
                id="name-in"
                v-model="policy.name"
                placeholder="Enter a name..."
              />
            </div>
          </b-input-group>

          <b-input-group class="mb-2 d-flex flex-column">
            <div>
              <label for="description-in">Description</label>
              <b-form-textarea
                id="description-in"
                v-model="policy.description"
                placeholder="And a description..."
                rows="5"
              />
            </div>
          </b-input-group>

          <b-input-group class="mb-2 d-flex flex-column">
            <label>Enterprise</label>
            <enterprise-search-select
              :selected="selectedEnterprise"
              @change="updateSelectedEnterprise"
            />
          </b-input-group>

          <b-input-group class="mb-2 d-flex flex-column">
            <div>
              <label for="name-in">MPOS Filename</label>
              <b-form-input
                id="name-in"
                v-model="policy.policy.filename"
                class="d-flex flex-column"
                placeholder="Filename..."
              />
            </div>
          </b-input-group>

          <b-input-group class="mb-2 d-flex flex-column">
            <div>
              <label for="filename-in">Onboard Keyboard Enabled?</label>
              <b-select
                v-model="policy.policy.onboard"
                :options="onboardOptions"
              />
            </div>
          </b-input-group>

          <b-input-group class="d-flex flex-row-reverse">
            <b-btn
              :class="{ disabled: !checkFormValid() }"
              variant="primary"
              @click="updatePolicy"
            >Update Policy
            </b-btn>
            <b-btn class="d-inline mr-2" @click="$emit('refresh')">Cancel</b-btn>
          </b-input-group>
        </b-form-group>
      </div>
    </div>

    <section v-else class="text-center m-5">
      <b-spinner variant="primary" label="Getting policy" />
    </section>
  </div>
</template>

<script>
import helperService from "@/services/HelperService";
import EnterpriseService from "@/services/EnterpriseService";
import EapiPolicyService from "@/services/EapiPolicyService";
import EnterpriseSearchSelect from "@/components/input/PaginatedSearchSelect/EnterpriseSearchSelect.vue";

export default {
  name: "UpdateEapiPolicy",
  components: {
    EnterpriseSearchSelect,
  },
  props: {
    existingPolicy: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      onboardOptions: [
        { value: false, text: "No" },
        { value: true, text: "Yes" },
      ],
      policy: {
        name: this.existingPolicy.name,
        description: this.existingPolicy.description,
        enterprise_uid: this.existingPolicy.enterprise_uid,
        policy: {
          s3_link: this.existingPolicy.policy.s3_link,
          filename: this.existingPolicy.policy.filename,
          onboard: this.existingPolicy.policy.onboard,
        },
      },
      selectedEnterprise: null,
      loading: true,
    };
  },
  mounted() {
    this.getEnterprise();
  },
  methods: {
    async getEnterprise() {
      this.loading = true;
      try {
        const res = await EnterpriseService.getEnterprise(this.$props.existingPolicy.enterprise_uid)
        this.selectedEnterprise = res.data;
      } catch (err) {
        helperService.showNotfyErr(
          this.$toast,
          err,
          "Could not update enterprise, please refresh and try again"
        );
      } finally {
        this.loading = false;
      }
    },
    async updatePolicy() {
      this.loading = true;
      try {
        await EapiPolicyService.updatePolicy(
          this.existingPolicy.id,
          this.policy
        );
        helperService.showNotfySuccess(
          this.$toast,
          `Updated EAPI policy ${this.policy.name} Successfully`
        );
        this.$emit("refresh");
      } catch (err) {
        helperService.showNotfyErr(
          this.$toast,
          err,
          "Could not update EAPI policy, please refresh and try again"
        );
      } finally {
        this.loading = false;
      }
    },
    checkFormValid() {
      const name = this.policy.name.length > 0;
      const filename = this.policy.policy.filename.length > 0;
      const enterpriseUid = this.policy.enterprise_uid.length > 0;

      return name && filename && enterpriseUid;
    },
    updateSelectedEnterprise(enterprise) {
      if (!enterprise) {
        this.policy.enterprise_uid = "";
      } else {
        this.policy.enterprise_uid = enterprise.uid;
      }
    },
  },
};
</script>

<style scoped></style>
